import * as React from 'react';
import { Link } from 'gatsby';
import '../index.scss';
import {Dot, Logo} from "../components/Logo";
import {Page} from "../components/Page";

const NotFoundPage = () => {
  return <Page title='Not found'>
    <section>
      <Link to='/' className='no-style'><Logo/></Link>
      <div className='spacer'/>
      <h1>Sorry, Page Not Found<Dot/></h1>
      <Link to="/">Go to main page</Link>
    </section>
  </Page>;
};

export default NotFoundPage;
